<template>
  <b-overlay :show="submitting" rounded="sm">
    <b-card class="border-0">
      <b-form @submit.prevent="saveBlog">
        <b-button class="mb-3" type="submit" variant="primary">Save Blog</b-button>
        <b-button class="mb-3 ml-3" variant="primary" v-if="blog.id > 0" :href="blog_url" target="_blank">View Blog</b-button>
        <b-row>
          <b-col cols="9">
            <b-row>
              <b-col cols="8">
                <!-- TITLE -->
                <b-form-group label="Title" label-for="form-blog-title">
                  <b-form-input
                    id="form-blog-title"
                    v-model="blog.title"
                    type="text"
                    placeholder="Enter blog title"
                    required maxlength="255"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <!-- DATE PUBLISHED -->
                <b-form-group label="Date Published" label-for="form-blog-date_published">
                  <b-form-datepicker
                    id="form-blog-date_published"
                    v-model="blog.date_pub">
                  </b-form-datepicker>
                  <b-form-timepicker v-model="blog.time_pub" locale="kr"></b-form-timepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- URL -->
            <b-form-group label="Url" label-for="form-blog-url">
              <b-form-input
                id="form-blog-url"
                v-model="blog.url"
                type="text"
                placeholder="Enter blog url"
                required maxlength="255"
              ></b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="3">
                <!-- IMAGE -->
                <b-form-group label="Cover Image">
                  <b-img :src="image.thumb_url" fluid alt="Blog cover image" block></b-img>
                  <b-button size="sm" variant="outline-secondary" v-b-modal.modal-blog-image>Select image</b-button>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <!-- CATEGORIES -->
                <b-form-group label="Categories:">
                  <b-form-checkbox-group
                    id="form-blog-categories"
                    v-model="blog.categories"
                    name="categories"
                  >
                    <b-form-checkbox v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col cols="5">
                <!-- SHORT DESC -->
                <b-form-group label="Short description" label-for="form-blog-short_desc">
                  <b-form-textarea
                    id="form-blog-short_desc"
                    v-model="blog.short_desc"
                    placeholder="Enter blog short description"
                    required rows="4" maxlength="255"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- CONTENT -->
            <b-form-group label="Content" label-for="form-blog-content">
              <Editor
                id="form-blog-content"
                api-key="79v7tnyuktquls0w8yv70zvitduab319klzcp4zs29vdhgmu"
                :init="tinymceInit"
                v-model="blog.content"
              />
            </b-form-group>
            <!-- KEYWORDS -->
            <b-form-group label="Keywords" label-for="form-blog-keywords">
              <b-form-input
                id="form-blog-keywords"
                v-model="blog.keywords"
                type="text"
                placeholder="Enter keywords separated by comma"
                required maxlength="65535"
              ></b-form-input>
            </b-form-group>
            <b-card header="SEO Assessor (Keywords)" no-body>
              <b-tabs pills card>
                <b-tab v-for="(keyword, index) in keywords" :key="index"
                  :title="keyword">
                  <SeoAssessor
                    :keyword="keyword"
                    :title="blog.title"
                    :titleWidth="seo.titleWidth"
                    :description="blog.short_desc"
                    :url="blog.url"
                    :text="blog.content"
                    :locale="seo.locale"
                    :translations="seo.translations"
                    :resultFilter="assessorResultFilter" />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-form-group label="SEO Translations">
              <b-form-select v-model="seo.locale" :options="seo.localeOptions" @change="changeSeoLocale" />
            </b-form-group>
            <b-card header="Snippet Preview" class="mb-3">
              <SnippetPreview
                :title="blog.title"
                :description="blog.short_desc"
                :url="blog.url"
                baseUrl="https://textree.co.kr/blog/"
                @update:titleWidth="(value) => seo.titleWidth = value"
                @update:titleLengthPercent="(value) => seo.titleLengthPercent = value"
                @update:descriptionLengthPercent="(value) => seo.descriptionLengthPercent = value"/>
            </b-card>
            <b-card header="Content Assessor" class="mb-3">
              <ContentAssessor
                :title="blog.title"
                :titleWidth="seo.titleWidth"
                :description="blog.short_desc"
                :url="blog.url"
                :text="blog.content"
                :locale="seo.locale"
                :translations="seo.translations"
                :resultFilter="assessorResultFilter" />
            </b-card>
          </b-col>
        </b-row>
      </b-form>
      <b-modal id="modal-blog-image" size="lg" ref="modalImage" title="Select blog image">
        <MediaUpload selection @select="imageSelected"/>
      </b-modal>
      <b-modal id="modal-blog-saved" ref="modalSaved" title="Blog" ok-only @ok="refreshPage">
        <p class="my-3">Blog details saved!</p>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import 'vue-yoast/dist/vue-yoast.css'
import { SnippetPreview, ContentAssessor, SeoAssessor } from 'vue-yoast'
import YoastLanguage_faIR from '@/plugins/yoast-translations/fa_IR.json'

import Editor from '@tinymce/tinymce-vue'
import image_upload_handler from '@/plugins/tinymce-image-handler'
import api from '@/api'

export default {
  props: {
    id: [String, Number]
  },
  components: {
    Editor,
    SnippetPreview,
    ContentAssessor,
    SeoAssessor,
    MediaUpload: () => import('@/views/admin/media/Upload')
  },
  data: () => ({
    blog: {
      id: 0,
      title: '',
      content: '',
      categories: [],
      short_desc: '',
      keywords: '',
      image_id: 0,
      url: '',
      date_pub: '',
      time_pub: '08:00:00'
    },
    images: [],
    seo: {
      locale: 'en_US',
      localeOptions: [
        {
          text: 'English (US)',
          value: 'en_US'
        },
      ],
      translations: null,
      titleWidth: 0,
      titleLengthPercent: 0,
      descriptionLengthPercent: 0,
    },
    image: {
      id: 0,
      thumb_url: '/media/20220503/thumb-1651589555.png',
      url: '/media/20220503/1651589555.png'
    },
    categories: [],
    tinymceInit: {
      selector: 'textarea',
      plugins: 'autolink directionality fullscreen image link media table charmap pagebreak nonbreaking blocks anchor insertdatetime advlist lists wordcount charmap emoticons code',
      menubar: 'file edit view insert format tools table',
      toolbar_mode: 'floating',
      toolbar_persist: true,
      toolbar1: 'styleselect | fontselect | fontsizeselect | lineheight',
      toolbar2: 'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor | blockquote | charmap emoticons | ltr rtl',
      toolbar3: 'undo redo | copy cut paste | image link anchor | fullscreen | dialog-media-btn',
      height: 480,
      images_upload_handler: image_upload_handler,
      automatic_uploads: true,
      smart_paste: true,
      browser_spellcheck: true,
      content_css: '/assets/css/tiny.css',
      setup: function (editor) {
        editor.on('OpenWindow', function() {
          const galleryDiv = document.getElementById('tiny-gallery-div')
          if (galleryDiv) {
            for (let image of window.tiny_images_list) {
              console.log(image)
              var img = document.createElement("img");
              img.src = image.value
              galleryDiv.appendChild(img)
            }

            var imgDomList = document.querySelectorAll('#tiny-gallery-div img');
            imgDomList.forEach(function(node) {
              node.addEventListener("click", function () {
                imgDomList.forEach(function (eaNode) {
                  eaNode.classList.remove("selected");
                })
                document.getElementById('tiny-media-image').value = node.src;
                node.classList.add("selected");
              })
            })
          }
        });
        editor.ui.registry.addButton('dialog-media-btn', {
          icon : 'gallery',
          onAction: function () {
            editor.windowManager.open({
              title: 'Select media',
              size: 'large',
              body: {
                type: 'panel',
                items: [
                  {
                    type: 'htmlpanel',
                    html: '<input type="hidden" id="tiny-media-image"/><div id="tiny-gallery-div"></div>'
                  }
                ]
              },
              buttons: [
                {
                  type: 'cancel',
                  name: 'closeButton',
                  text: 'Cancel'
                },
                {
                  type: 'submit',
                  name: 'submitButton',
                  text: 'Insert To Editor',
                  primary: true
                }
              ],
              onSubmit: function (api) {
                let source = document.getElementById('tiny-media-image').value;

                if(source != ''){
                  editor.focus();
                  editor.execCommand('mceInsertContent', false, `<p><img src="${source}" width="80%" /></p>`);
                  api.close();
                } else {
                  alert("Please select an image.");
                }
              }
            });
          }
        })
      }
    },
    submitting: false
  }),
  computed: {
    keywords: function () {
      return this.blog.keywords ? this.blog.keywords.split(',') : []
    },
    blog_url: function () {
      return 'https://textree.co.kr/blog/' + this.blog.url
    }
  },
  mounted () {
    this.getTinyList()
    this.getBlogCategories()
    if (parseInt(this.id) > 0) {
      this.getBlog()
    }
  },
  methods: {
    getTinyList: async function () {
      window.tiny_images_list = await api.admin.media.getTinyList();
    },
    assessorResultFilter: function (value) {
      return value
    },
    changeSeoLocale: function () {
      switch (this.seo.locale) {
        case 'fa_IR':
          this.seo.translations = YoastLanguage_faIR
          break;
        default:
          this.seo.translations = null
      }
    },
    getBlogCategories: async function () {
      let categories = await api.admin.blog.getBlogCategories()
      if (categories) {
        this.categories = []
        for (var category of categories) {
          if (category.shown) {
            this.categories.push(category);
          }
        }
      }
    },
    getBlog: async function () {
      let blog = await api.admin.blog.getBlog(this.id)
      if (blog) {
        this.blog = blog
        this.image.id = blog.image_id
        this.image.thumb_url = blog.image_url
        this.image.url = blog.image_url
      }
    },
    imageSelected: function (media) {
      this.blog.image_id = media.id
      this.image = media
      this.$refs['modalImage'].hide()
    },
    saveBlog: async function () {
      this.submitting = true
      let blog_id = await api.admin.blog.saveBlog(this.blog)
      if (blog_id > 0) {
        this.blog.id = blog_id
        this.$refs['modalSaved'].show()
      }
      this.submitting = false
    },
    refreshPage: function () {
      this.$router.replace({ path: '/admin/blogs/edit/' + this.blog.id })
    },
    slugify: function (text) {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\u0100-\uFFFF\w-]/g,'-') // Remove all non-word chars ( fix for UTF-8 chars )
        .replace(/-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    }
  }
}
</script>

<style>
#tiny-gallery-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
#tiny-gallery-div img {
  width: 30%;
  border: 5px solid transparent;
}
#tiny-gallery-div img.selected {
  border: 5px solid lime;
}
</style>
