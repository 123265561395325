<template>
  <b-overlay :show="submitting" rounded="sm">
    <b-card>
      <b-form @submit.prevent="saveClient">
        <b-row>
          <b-col cols="5">
            <!-- NAME -->
            <b-form-group label="Name" label-for="form-client-name">
              <b-form-input
                id="form-client-name"
                v-model="client.name"
                type="text"
                placeholder="Enter client name"
                required maxlength="255"
              ></b-form-input>
            </b-form-group>
            <!-- SUBNAME -->
            <b-form-group label="Sub Name" label-for="form-client-subname">
              <b-form-input
                id="form-client-subname"
                v-model="client.subname"
                type="text"
                placeholder="Enter client subname"
                maxlength="255"
              ></b-form-input>
            </b-form-group>
            <!-- DESCRIPTION -->
            <b-form-group label="Description" label-for="form-client-description">
              <editor
                id="form-client-description"
                api-key="79v7tnyuktquls0w8yv70zvitduab319klzcp4zs29vdhgmu"
                :init="tinymceInit"
                v-model="client.description"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <!-- MAIN CAT -->
            <b-form-group label="Categories:">
              <b-form-select
                id="form-client-main-cat"
                v-model="client.main_cat"
                name="main_cat">
                <b-form-select-option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <!-- CATEGORIES -->
            <b-form-group label="Categories:">
              <b-form-checkbox-group
                id="form-client-categories"
                v-model="client.categories"
                name="categories"
              >
                <b-form-checkbox v-for="category in categories" :key="category.id" :value="category.id" name="categories">{{category.name}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
            <!-- SECTORS -->
            <b-form-group label="Sectors:">
              <b-form-checkbox-group
                id="form-client-sectors"
                v-model="client.sectors"
                name="sectors"
              >
                <b-form-checkbox v-for="sector in sectors" :key="sector.id" :value="sector.id" name="sectors">{{sector.name}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
            <!-- INDUSTRIES -->
            <b-form-group label="Industries:">
              <b-form-checkbox-group
                id="form-client-industries"
                v-model="client.industries"
                name="industries"
              >
                <b-form-checkbox v-for="industry in industries" :key="industry.id" :value="industry.id" name="industries">{{industry.name}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <!-- LOGO 1 -->
            <b-form-group label="Logo1 image">
              <b-img :src="logos[0].thumb_url" fluid alt="Logo1 image" block></b-img>
              <b-button size="sm" variant="outline-secondary" @click="selectImage('logo1')">Select image</b-button>
            </b-form-group>
            <!-- LOGO 2 -->
            <b-form-group label="Logo2 image">
              <b-img :src="logos[1].thumb_url" fluid alt="Logo2 image" block></b-img>
              <b-button size="sm" variant="outline-secondary" @click="selectImage('logo2')">Select image</b-button>
            </b-form-group>
            <!-- IMAGE -->
            <b-form-group label="Client Image">
              <b-img :src="image.thumb_url" fluid alt="Client image" block></b-img>
              <b-button size="sm" variant="outline-secondary" @click="selectImage('image')">Select image</b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary">Save Client</b-button>
      </b-form>
      <b-modal id="modal-image" size="lg" ref="modalImage" title="Select image">
        <MediaUpload selection @select="imageSelected"/>
      </b-modal>
      <b-modal id="modal-client-saved" ref="modalSaved" title="Client" ok-only @ok="refreshPage">
        <p class="my-3">Client details saved!</p>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import api from '@/api'
import slugify from '@/plugins/slugify'

export default {
  props: {
    id: [String, Number]
  },
  components: {
    'editor': Editor,
    MediaUpload: () => import('@/views/admin/media/Upload')
  },
  data: () => ({
    client: {
      id: 0,
      name: '',
      subname: '',
      logos: [0, 0],
      image_id: 0,
      description: '',
      industries: [],
      sectors: [],
      main_cat: 0,
      categories: []
    },
    imagefor: '',
    image: {
      id: 0,
      thumb_url: '/media/20220503/thumb-1651589555.png',
      url: '/media/20220503/1651589555.png'
    },
    logos: [
      {
        id: 0,
        thumb_url: '/media/20220503/thumb-1651589555.png',
        url: '/media/20220503/1651589555.png'
      },
      {
        id: 0,
        thumb_url: '/media/20220503/thumb-1651589555.png',
        url: '/media/20220503/1651589555.png'
      }
    ],
    categories: [],
    sectors: [],
    industries: [],
    tinymceInit: {
      plugins: 'importcss autolink autosave save directionality visualblocks visualchars fullscreen image link media table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
      toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | numlist bullist | forecolor backcolor | pagebreak | fullscreen | ltr rtl',
      height: 320,
      smart_paste: true
    },
    submitting: false
  }),
  mounted () {
    this.getClientCategories()
    this.getClientSectors()
    this.getClientIndustries()
    if (parseInt(this.id) > 0) {
      this.getClient()
    }
  },
  methods: {
    slugify,
    getClientCategories: async function () {
      let categories = await api.admin.portfolio.getClientCategories()
      if (categories) {
        this.categories = categories
      }
    },
    getClientSectors: async function () {
      let sectors = await api.admin.portfolio.getClientSectors()
      if (sectors) {
        this.sectors = sectors
      }
    },
    getClientIndustries: async function () {
      let industries = await api.admin.portfolio.getClientIndustries()
      if (industries) {
        this.industries = industries
      }
    },
    getClient: async function () {
      let client = await api.admin.portfolio.getClient(this.id)
      if (client) {
        this.client = client
        if (client.image_id > 0) {
          this.image = {
            id: client.image_id,
            thumb_url: client.image_url,
            url: client.image_url
          }
        }
        var ctr=0
        for (var mlogo of client.logoFiles) {
          this.logos[ctr] = mlogo
          ctr++
        }
      }
    },
    selectImage: function (imagefor) {
      this.imagefor = imagefor
      this.$refs['modalImage'].show()
    },
    imageSelected: function (media) {
      switch (this.imagefor) {
        case 'logo1':
          this.client.logos[0] = media.id
          this.logos[0].thumb_url = media.url
          break;
        case 'logo2':
          this.client.logos[1] = media.id
          this.logos[1].thumb_url = media.url
          break;
        case 'image':
          this.client.image_id = media.id
          this.image.thumb_url = media.url
          break;
      }
      this.$refs['modalImage'].hide()
    },
    saveClient: async function () {
      this.submitting = true
      let client_id = await api.admin.portfolio.saveClient(this.client)
      if (client_id > 0) {
        this.client.id = client_id
        this.$refs['modalSaved'].show()
      }
      this.submitting = false
    },
    refreshPage: function () {
      this.$router.replace({ path: '/admin/portfolio/edit/' + this.client.id })
    }
  }
}
</script>

<style scoped>
</style>
